import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import { hasPermission } from "../../../../RAFComponents/helpers/PermissionHelper";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import ManageUser from "../../../ActiveContacts/User/ManageUser";
import ManageUserPermissionGroup from "../../../ActiveContacts/User/ManageUserPermissionGroup";
import {
  ManageUserDlgMode,
  checkLoginExistsByRelatedToUID,
} from "../../../ActiveContacts/User/UserHelper";
import { UserRow } from "../../../ActiveContacts/User/UserRow";

interface IProps {
  firstName: string;
  lastName: string;
  relatedTo: string;
  relatedToUID: string;
  relatedToType: string;
  displayMode: "button" | "default";
  permissionNameManageLogin: string;
}

interface IState {
  isLogInExist: boolean;
  showCreateUserDlg: boolean;
  showManageUserRoleDlg: boolean;
  linkedUserRow: UserRow;
}

function EmployeeContactUserLogIn({ ...props }: PropsWithChildren<IProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const hasPermissionToManageLogin = hasPermission(
    permissionValue,
    props.permissionNameManageLogin
  );

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLogInExist: null,
      showCreateUserDlg: false,
      showManageUserRoleDlg: false,
      linkedUserRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.relatedToUID]);

  const refresh = async () => {
    setState({
      isLogInExist: null,
      linkedUserRow: null,
      showCreateUserDlg: false,
      showManageUserRoleDlg: false,
    });
    const linkedUserRow = await checkLoginExistsByRelatedToUID(
      props.relatedToUID,
      props.relatedToType
    );
    if (isNotNullAndUndefined(linkedUserRow)) {
      setState({ isLogInExist: true, linkedUserRow });
    } else {
      setState({ isLogInExist: false, linkedUserRow: null });
    }
  };

  //update user permission group start
  const onClickManageUserRole = () => {
    setState({ showManageUserRoleDlg: true });
  };

  const manageUserRoleDlgContent = () => {
    if (state.showManageUserRoleDlg) {
      return (
        <ManageUserPermissionGroup
          objectUID={state.linkedUserRow.UID}
          isActive
          onSave={refresh}
          onClose={closeManageUserRoleDlg}
        />
      );
    } else {
      return null;
    }
  };

  const closeManageUserRoleDlg = () => {
    setState({ showManageUserRoleDlg: false });
  };
  //update user permission group end

  //manage login start
  const onClickManageLogIn = () => {
    if (state.isLogInExist) {
      setState({ showCreateUserDlg: true });
      //DeleteClicked();
      //removeManageLogIn();
    } else {
      setState({ showCreateUserDlg: true });
    }
  };

  //create user start
  const createUserContent = () => {
    if (state.showCreateUserDlg) {
      const initialValues = new UserRow();
      initialValues.FirstName = props.firstName;
      initialValues.LastName = props.lastName;
      initialValues.RelatedTo = props.relatedTo;
      initialValues.RelatedToUID = props.relatedToUID;
      initialValues.RelatedToType = props.relatedToType;

      return (
        <ManageUser
          objectUID={state.isLogInExist ? state.linkedUserRow.UID : null}
          onSave={refresh.bind(this)}
          onClose={closeCreateUserDlg.bind(this)}
          userDlgMode={
            state.isLogInExist
              ? ManageUserDlgMode.Details
              : props.relatedToType === RAFEntityName.Employee
                ? ManageUserDlgMode.CreateUserLinkEmployee
                : props.relatedToType === CareEsioEntity.CareRecipient.EntityName
                  ? ManageUserDlgMode.CreateUserLinkCareRecipient :
                  ManageUserDlgMode.CreateUserLinkContact
          }
          initialValues={initialValues}
          isActive
        />
      );
    } else {
      return null;
    }
  };

  const closeCreateUserDlg = () => {
    setState({ showCreateUserDlg: false });
  };
  //create user end

  const customLogInStatusValue = () => {
    if (state.isLogInExist) {
      return (
        <div className="col-12">
          <div className="row g-2">
            <div className="col-auto">
              <div
                id="loginStatus"
                className={`details-value`}
              //style={{ border: `1px solid ${loginStatusColorCode}`, color: loginStatusColorCode, padding: '0 5px', borderRadius: '5px' }}
              >
                <span>
                  <span
                    className="fa fa-minus-circle me-2"
                  //style={{ color: loginStatusColorCode }}
                  ></span>
                  {state.linkedUserRow.UserName}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (hasPermissionToManageLogin) {
      return (
        <div className="col-12">
          <RAFButtonComponent
            action={RAFButtonConstant.Add}
            btnContent="Add Login"
            className={'new_style btn_brand_primary raf_sm outline'}
            onClick={onClickManageLogIn}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const customLogInStatusRightSectionContent = () => {
    if (state.isLogInExist && hasPermissionToManageLogin) {
      return (
        <div className="col-auto d-flex align-items-center">
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            iconBtn
            className="e-outline new_style raf_sm"
            onClick={onClickManageLogIn}
            enableToolTip={false}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const customUserRoleValue = () => {
    if (
      isNotNullAndUndefined(state.linkedUserRow) &&
      isNotNullAndUndefined(state.linkedUserRow.PermissionGroup)
    ) {
      return (
        <div className="col-12">
          <div className="row g-2">
            <div className="col-auto">
              <div
                id="loginStatus"
                className={`details-value`}
              //style={{ border: `1px solid ${loginStatusColorCode}`, color: loginStatusColorCode, padding: '0 5px', borderRadius: '5px' }}
              >
                <span>
                  <span
                    className="fa fa-minus-circle me-2"
                  //style={{ color: loginStatusColorCode }}
                  ></span>
                  {state.linkedUserRow.PermissionGroup}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const customUserRoleRightSectionContent = () => {
    if (
      isNotNullAndUndefined(state.linkedUserRow) &&
      isNotNullAndUndefined(state.linkedUserRow.PermissionGroup)
    ) {
      return (
        <div className="col-auto d-flex align-items-center">
          <RAFButtonComponent
            action={RAFButtonConstant.Edit}
            iconBtn
            className="e-outline new_style raf_sm"
            onClick={onClickManageUserRole}
            enableToolTip={false}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getBodyContent = () => {
    if (props.displayMode === "button") {
      if (hasPermissionToManageLogin) {
        return (
          <RAFButtonComponent
            type="button"
            className={'new_style btn_brand_primary raf_sm outline'}
            iconCss="fas fa-user-plus"
            isPrimary
            onClick={onClickManageLogIn}
            btnContent={
              isNotNullAndUndefined(state.isLogInExist)
                ? state.isLogInExist === true
                  ? "Edit Login"
                  : "Add Login"
                : "Loading..."
            }
            iconBtn={BrowserIsDevice}
          />
        );
      } else {
        return null;
      }
    } else {
      return (
        <>
          <div className="col-12 border-bottom"></div>
          <RAFDetailFieldCustom
            labelClassName="profile_card_details_label"
            customValue={customLogInStatusValue()}
            rowClassName="gx-0 gy-2"
            title="Login Status"
            colClassName="col"
            customRightSectionContent={customLogInStatusRightSectionContent()}
          />
          {isNotNullAndUndefined(state.linkedUserRow) &&
            isNotNullAndUndefined(state.linkedUserRow.PermissionGroup) && (
              <>
                <div className="col-12 border-bottom"></div>
                <RAFDetailFieldCustom
                  labelClassName="profile_card_details_label"
                  customValue={customUserRoleValue()}
                  rowClassName="gx-0 gy-2"
                  title="Permission Group"
                  colClassName="col"
                  customRightSectionContent={customUserRoleRightSectionContent()}
                />
              </>
            )}
        </>
      );
    }
  };

  return (
    <div className="col-12">
      <div className="row gy-3 gx-0">
        {getBodyContent()}
      </div>
      {state.showCreateUserDlg && (
        <DialogComponent
          header={state.isLogInExist ? "Edit Login" : "Add Login"}
          showCloseIcon
          visible={state.showCreateUserDlg}
          cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
          //cssClass="rightDialog createEditForm full-height form-center-dialog"
          content={createUserContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateUserDlg.bind(this)}
        />
      )}
      {state.showManageUserRoleDlg && (
        <DialogComponent
          header={"Edit Permission Group"}
          showCloseIcon
          visible={state.showManageUserRoleDlg}
          cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header"
          //cssClass="rightDialog createEditForm full-height form-center-dialog"
          content={manageUserRoleDlgContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeManageUserRoleDlg.bind(this)}
        />
      )}
    </div>
  );
}

export default React.memo(EmployeeContactUserLogIn);
